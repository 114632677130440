// extracted by mini-css-extract-plugin
export var slideshow = "slideshow-module--slideshow--q-ECb";
export var slide = "slideshow-module--slide--1IZDn";
export var slideBlend = "slideshow-module--slide-blend--UTARy";
export var cache = "slideshow-module--cache--1qbkO";
export var current = "slideshow-module--current--3a2bk";
export var nextStack = "slideshow-module--nextStack--1ScG6";
export var prevStack = "slideshow-module--prevStack--3k7Iv";
export var slideBack = "slideshow-module--slide-back--2PNaP";
export var slideBackBlack = "slideshow-module--slide-back-black--1R4i0";
export var disableAnimation = "slideshow-module--disable-animation--dtWVA";
export var player = "slideshow-module--player--3SB8X";
export var controlContainer = "slideshow-module--control-container--2IHb2";
export var control = "slideshow-module--control--34hA6";
export var controlFull = "slideshow-module--control-full--3VDp_";
export var controlPrev = "slideshow-module--control-prev--2MRpZ";
export var controlNext = "slideshow-module--control-next--2-SwG";
export var advertising = "slideshow-module--advertising--2WTKR";
export var advertisingActive = "slideshow-module--advertising-active--2p6EE";
export var loopPrev = "slideshow-module--loopPrev---Hymy";
export var loopNext = "slideshow-module--loopNext--1SD6w";
export var portrait = "slideshow-module--portrait--3pqKn";
export var paysage = "slideshow-module--paysage--3Cv1k";
export var marqueelike = "slideshow-module--marqueelike--pV3ks";